@import ../styles/_vars
  
.advantage-card

  display: flex
  flex-direction: column
  gap: 16px

  @media (max-width: $md)
    align-items: center
    gap: 0

  &__img
    max-height: 70px
    object-fit: contain
    width: 100px
    object-position: left

    @media (max-width: $md)
      object-position: center
      margin-bottom: 1rem

  .promo-text
    font-size: 1.3rem

    @media (max-width: $md)
      margin-bottom: 1rem

  .body-text
    line-height: 1.5
    font-weight: 400

    @media (max-width: $md)
      text-align: center